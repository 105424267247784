<template>
  <div>
    <section class="hero is-large has-bg-img">
      <div class="hero-body">
        <div class="container has-text-right has-text-break">
          <h1 class="title has-text-primary-dark my-6">
            {{ $t("globalHead") }}
          </h1>
          <span class="subtitle my-3 has-text-break has-text-white">{{
            $t("globalText1")
          }}</span>
          <br /><br />
          <span class="subtitle my-3 has-text-break has-text-white">{{
            $t("globalText2")
          }}</span>
        </div>
      </div>
    </section>
    <figure>
      <img :src="require('@/assets/Global/Map.png')" alt="" />
    </figure>
    <h1 class=" title has-text-center has-text-primary-dark my-6">
      {{ $t("clients") }}
    </h1>
    <figure>
      <img :src="require('@/assets/Logos e ilustraciones/Logos.jpg')" alt="" />
    </figure>
  </div>
</template>

<script>
export default {};
</script>

<style>
.has-bg-img {
  background-image: url("~@/assets/Global/Enmascarar grupo 9.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.has-text-break {
  white-space: pre;
  word-wrap: break-all;
  height: auto;
}
</style>
